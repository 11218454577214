import { dateOnly } from "utils/types";
import {
  AchievementCategoryItemDto,
  AchievementFilterStatus,
} from "./achievementTypes";
import { ListDto, QueryDateRange } from "./commonTypes";

export type CompanyCalendarUserDto = {
  id: number;
  firstName: string;
  familyName: string;
  avatarImageUrl: string | null;
  isOnline: boolean;
  position: string | null;
  birthDate: string | Date;
  hireDate: string | Date;
};

export type CompanyCalendarAchievementDto = {
  id: number;
  name: string;
  previewImageUrl: string;
  categories: AchievementCategoryItemDto[];
  startDate: dateOnly | null;
  activeUntil: dateOnly | null;
  coins: number;
  isPrivate: boolean;
  filterStatus: AchievementFilterStatus;
};

export enum CompanyHolidayDeductDay {
  dayOff = "dayOff",
  workingDay = "workingDay",
}

export type CompanyCalendarHolidayDto = {
  id: number;
  name: string;
  date: string;
  previewImageUrl: string;
  locations: string[];
  deductDays: CompanyHolidayDeductDay;
};

export type CompanyCalendarDateItem = {
  startEventDate: string | Date;
  achievements: CompanyCalendarAchievementDto[];
  holidays: CompanyCalendarHolidayDto[];
  birthdays: CompanyCalendarUserDto[];
  anniversaries: CompanyCalendarUserDto[];
};

export type ResponseCompanyCalendar = ListDto<CompanyCalendarDateItem>;

export type CompanyCalendarCategoryWithAchievements = {
  category: AchievementCategoryItemDto;
  achievementCount: number;
  achievements: CompanyCalendarAchievementDto[];
};

export enum CompanyCalendarEventType {
  all = "All",
  achievements = "Achievements",
  holidays = "Holidays",
}

export type CompanyCalendarQueryParams = {
  eventType?: CompanyCalendarEventType;
} & QueryDateRange;

export type CompanyCalendarTeamUserDto = Omit<
  CompanyCalendarUserDto,
  "position"
>;

export type CompanyCalendarTeamDates = {
  birthdays: CompanyCalendarTeamUserDto[];
  anniversaries: CompanyCalendarTeamUserDto[];
};

export type CompanyCalendarRecentAchievementDto = {
  id: number;
  name: string;
  startDate: dateOnly | null;
  activeUntil: dateOnly | null;
  filterStatus: AchievementFilterStatus;
};

export type CompanyCalendarRecentAchievementsList =
  ListDto<CompanyCalendarRecentAchievementDto>;
