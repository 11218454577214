import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { defaultListState } from "../../appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { CompanyCalendarState } from "../types";
import { logoutUserAction } from "./authReducer";

import { ClientDateRange } from "api/types/commonTypes";
import {
  CompanyCalendarDateItem,
  CompanyCalendarEventType,
  CompanyCalendarRecentAchievementDto,
  CompanyCalendarTeamDates,
} from "api/types/companyCalendarType";
import { currentMonth } from "pages/events/achievements/companyCalendar/utils/consts";

const initialState: CompanyCalendarState = {
  calendarData: defaultListState,
  dateRange: currentMonth,
  eventType: CompanyCalendarEventType.all,
  teamDates: defaultListState,
  recentAchievements: defaultListState,
};

const eventsSlice = createSlice({
  name: "companyCalendar",
  initialState,
  reducers: {
    setLoadingCalendarData: (state) => ({
      ...state,
      calendarData: setStateLoading(state.calendarData),
    }),
    setCalendarData: (
      state,
      action: PayloadAction<CompanyCalendarDateItem[]>
    ) => ({
      ...state,
      calendarData: setStateData(state.calendarData, action.payload),
    }),
    setCalendarDataError: (state, action: PayloadAction<string>) => ({
      ...state,
      calendarData: setStateError(state.calendarData, action.payload),
    }),
    setCalendarDateRange: (state, action: PayloadAction<ClientDateRange>) => ({
      ...state,
      dateRange: action.payload,
    }),
    setCalendarEventType: (
      state,
      action: PayloadAction<CompanyCalendarEventType>
    ) => ({
      ...state,
      eventType: action.payload,
    }),
    setLoadingTeamDates: (state) => ({
      ...state,
      teamDates: setStateLoading(state.teamDates),
    }),
    setTeamDates: (state, action: PayloadAction<CompanyCalendarTeamDates>) => ({
      ...state,
      teamDates: setStateData(state.teamDates, action.payload),
    }),
    setTeamDatesError: (state, action: PayloadAction<string>) => ({
      ...state,
      teamDates: setStateError(state.teamDates, action.payload),
    }),
    setLoadingRecentAchievements: (state) => ({
      ...state,
      recentAchievements: setStateLoading(state.recentAchievements),
    }),
    setRecentAchievements: (
      state,
      action: PayloadAction<CompanyCalendarRecentAchievementDto[]>
    ) => ({
      ...state,
      recentAchievements: setStateData(
        state.recentAchievements,
        action.payload
      ),
    }),
    setRecentAchievementsError: (state, action: PayloadAction<string>) => ({
      ...state,
      recentAchievements: setStateError(
        state.recentAchievements,
        action.payload
      ),
    }),
    resetCalendarData: (state) => ({
      ...state,
      calendarData: defaultListState,
    }),
    resetTeamDates: (state) => ({
      ...state,
      teamDates: defaultListState,
    }),
    resetRecentAchievements: (state) => ({
      ...state,
      recentAchievements: defaultListState,
    }),
    resetCompanyCalendarState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default eventsSlice.reducer;

export const {
  setCalendarData,
  setCalendarDataError,
  setLoadingCalendarData,
  setCalendarDateRange,
  setCalendarEventType,
  setLoadingTeamDates,
  setTeamDates,
  setTeamDatesError,
  setLoadingRecentAchievements,
  setRecentAchievements,
  setRecentAchievementsError,
  resetRecentAchievements,
  resetTeamDates,
  resetCalendarData,
  resetCompanyCalendarState,
} = eventsSlice.actions;
