import { QueryDateRange, ListDto, QueryParams } from "./commonTypes";

export type CategoryWithParticipantDto = {
  categoryId: number;
  categoryName: string;
  peopleCount: number;
};

export type CategoriesWithParticipantsListDto =
  ListDto<CategoryWithParticipantDto>;

export type CategoryWithAchievementsDto = {
  categoryId: number;
  categoryName: string;
  achievementsCount: number;
};

export type CategoriesWithAchievementsListDto =
  ListDto<CategoryWithAchievementsDto>;

export type PeopleEventsEngagementStatsDto = {
  date: string;
  engagedPeopleCount: number;
  waitingForApprovePeopleCount: number;
  notEngagedPeopleCount: number;
};

export enum EmployeeEngagementStatuses {
  all = "all",
  engaged = "engagedPeopleCount",
  waitingForApprove = "waitingForApprovePeopleCount",
  notEngaged = "notEngagedPeopleCount",
}

export type AchievementsParticipantsRatingItemDto = {
  rating: number;
  achievementId: number;
  achievementName: string;
  participantsCount: number;
  achievementCategories: { categoryId: number; categoryName: string }[];
  receivedCoins: number;
};

export type AchievementsParticipantsRatingListDto =
  ListDto<AchievementsParticipantsRatingItemDto>;

export type AchievementsInsightsDto = {
  activePlayersCount: number;
  activePlayersCountPercentageDifference: number | null;
  activeEventsCount: number;
  activeEventsCountPercentageDifference: number | null;
  topCategory: string | null;
};

export type AchievementsMostActiveUsersParams = QueryParams &
  QueryDateRange;

export type AchievementMostActiveUsersItemDto = {
  userId: number | null;
  firstName: string | null;
  familyName: string | null;
  avatarImageUrl: string | null;
  position: string | null;
  isOnline: boolean;
  approvedCount: number | null;
  onReviewCount: number | null;
  totalCoins: number | null;
  topCategory: string | null;
};

export enum PeopleOverEventsMainFilters {
  position = "Position",
  location = "Location",
  department = "Department",
  manager = "Manager",
}

export type PeopleOverEventsQueryParams = QueryParams &
  QueryDateRange & {
    groupBy: PeopleOverEventsMainFilters;
  };

export type PeopleOverEventsItemDto = {
  groupColumn: string | null;
  approvedCount: number | null;
  onReviewCount: number | null;
  totalCoins: number | null;
  topCategory: string | null;
};

export enum AchievementAnalyticsSortBy {
  TopCategory = "TopCategory",
  ApprovedCount = "ApprovedCount",
  OnReviewCount = "OnReviewCount",
  TotalCoins = "TotalCoins",
}
