import {
  QueryDateRange,
  DateRange,
  ExportFormat,
  PageDto,
  QueryParams,
} from "./commonTypes";
import { dateOnly } from "../../utils/types";

export type MerchCoinStatisticsDto = {
  usersCoinsCount: number;
  storeCoinsCount: number;
  spentCoinsCount: number;
  spentCoinsPercentageDifference: null | number;
};

export enum PopularMerchItemsTabs {
  all = "All",
  available = "Available",
  notAvailable = "NotAvailable",
  outOfStock = "OutOfStock",
}

export type PopularMerchItemsQueryParams = {
  pageNumber: number;
  pageSize: number;
  status: PopularMerchItemsTabs;
};

export type PopularMerchItemDto = {
  id: number;
  name: string | null;
  imageUrl: string | null;
  sold: number;
  isActive: boolean;
  currentQuantity: number | null;
};

export type PopularMerchItemsPageDto = PageDto<PopularMerchItemDto>;

export type ItemsOverTimeQueryParams = QueryParams & DateRange;

export type ItemOverTimeDto = {
  id: number;
  name: string | null;
  category: string | null;
  imageUrl: string | null;
  price: number;
  sold: number;
  currentQuantity: number;
  totalCoins: number;
  activeUntil: null | dateOnly;
};

export type ItemsOverTimePageDto = PageDto<ItemOverTimeDto>;

export enum ItemsOverTimeSortBy {
  sold = "Sold",
  price = "Price",
  stockPrice = "StockPrice",
  stockQuantity = "StockQuantity",
  activeUntil = "ActiveUntil",
}

type ExportItemsOverTimeSortingOptions = {
  orderBy: ItemsOverTimeSortBy;
  isDescending: boolean;
};

export type ExportItemsOverTimeDataRequest = {
  exportFormat: ExportFormat;
  sorting: ExportItemsOverTimeSortingOptions;
  dateRange?: QueryDateRange;
};
